:root {
  --padding: 6rem 10%;
  --padding-lg: 3rem;

  --itemWidth: 13.25rem;
  --itemHeight: 20rem;

  @media screen and (min-width: 768px) {
    --itemWidth: 17.8rem;
    --itemHeight: 26rem;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Rammetto+One&display=swap");

::selection {
  background: #333;
  color: #fff;
}
::-moz-selection {
  background: #333;
  color: #fff;
}

button:focus {
  outline: none;
}

html {
  font-size: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background-color: #333;
}


@media (prefers-color-scheme: dark) {
  body {
    background-color: #333;
    color: #fff;
  }

  .Social-anchor-circle {
    background-color: #fff;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.u-visually-hidden {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

a {
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

a:hover,
a:focus {
  color: inherit;
}

a:active,
a:hover,
a:focus {
  outline: 0;
}

button {
  text-transform: none;
  cursor: pointer;
  background-color: transparent;
  margin: 0;
  border: 0;
  padding: 0;
  text-align: left;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

a,
button,
input,
select,
textarea,
label,
summary {
  touch-action: manipulation;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

h1,
h2 {
  font-size: 1.3rem;
  margin: 0;
}

h3 {
  font-size: inherit;
  margin: 0 0 1rem;
}

p {
  margin: 0 0 1rem;
}

h2 {
  margin: 0 0 0.5rem;
}

.root {
  overflow: hidden;
  min-height: 100vh;
}

.container {
  margin: 0 auto;
  padding: 1.5rem 1.25rem;
  max-width: 60rem;
}

.section {
  margin-bottom: 5rem;
}

.sub-heading-wrapper {
  overflow: hidden;
  margin-bottom: 1.35rem;
}

.sub-heading {
  font-family: "Rammetto One";
  font-size: 1.25rem;
  font-weight: normal;
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .container {
    position: inherit;
    padding: var(--padding-lg);
  }

  body {
    font-size: 0.85rem;
  }
}

.card {
  border: 0;
  display: inline-flex;
  flex-direction: column;
  font-size: 0.9rem;
  height: var(--itemHeight);
  justify-content: space-between;
  margin: 0 1rem;
  outline: 0;
  padding: 0;
  position: relative;
  white-space: normal;
  width: var(--itemWidth);
}
.card:first-child {
  margin-left: 0;
}
.card:last-child {
  margin-right: 0;
}
.card.is-active,
.card:hover {
  z-index: 1;
}
.card.is-active .shadow:before,
.card:hover .shadow:before {
  opacity: 0.5;
  -webkit-transform: none;
  transform: none;
}
.card.is-active .shadow:after,
.card:hover .shadow:after {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
.backdrop {
  height: 100%;
  height: var(--itemHeight);
  justify-content: flex-end;
  overflow: hidden;
  position: absolute;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  width: var(--itemWidth);
  will-change: transform;
}
.backdrop,
.inner {
  display: flex;
  flex-direction: column;
}
.inner {
  background-color: #fff;
  height: 100%;
  justify-content: space-between;
}
.card_content {
  padding: 2.5rem 2.25rem;
  position: absolute;
  z-index: 1;
}
.card_title {
  font-size: 1rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
}
.card_media {
  pointer-events: none;
  position: relative;
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
  width: 100%;
  will-change: transform;
}
.Icon-fallback.Icon--card,
.Icon-project.Icon--card {
  margin-bottom: 1rem;
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .card {
    font-size: 1rem;
  }
  .card_content {
    padding: 3rem 2.5rem;
  }
  .Icon-fallback.Icon--card,
  .Icon-project.Icon--card {
    margin-bottom: 2rem;
  }
}
.shadow {
  height: var(--itemHeight);
  left: 0;
  position: absolute;
  top: 0;
  width: var(--itemWidth);
  will-change: opacity, transform;
  z-index: -1;
}
.shadow-upper {
  box-shadow: -60px 60px 40px 10px rgba(51, 51, 51, 0.15);
  opacity: 0.1;
  -webkit-transform: scale(0.75);
  transform: scale(0.75);
  -webkit-transform-origin: top right;
  transform-origin: top right;
}
.shadow-lower {
  box-shadow: -4px 3px 7px 1px hsla(0, 0%, 40%, 0.06);
}
.Dragger_Dragger__pixlv {
  position: relative;
  z-index: 1;
}
.name {
  align-items: center;
  display: flex;
  display: block;
  font-family: Rammetto One;
  font-size: 2rem;
  font-weight: 400;
  height: 100%;
  justify-content: center;
  margin-bottom: 0.75rem;
  width: 100%;
  width: 15rem;
}
.name,
.name-split {
  overflow: hidden;
  position: relative;
}
.name-split {
  height: 40px;
  line-height: 40px;
  width: 100%;
  will-change: transform, opacity;
}
.name-split > div {
  overflow: hidden;
}
.title {
  margin-bottom: 2rem;
  will-change: opacity;
}
.intro {
  display: block;
  font-size: 1rem;
  margin: 0;
  max-width: 36rem;
  padding-right: 2rem;
  font-weight: 500;
}
.logo {
  margin-bottom: 1.25rem;
  max-height: 11rem;
  max-width: 10rem;
}
.cursor-pointer {
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .title {
    display: flex;
  }
  .intro {
    padding-top: 3px;
  }
  .name {
    margin-bottom: 0;
  }
}
.social-links {
  display: flex;
  margin-bottom: 3rem;
  position: relative;
  z-index: 0;
}
.social-icon {
  fill: currentColor;
  height: 2.25rem;
  width: 2.25rem;
  z-index: 1;
}
.social-anchor {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
  position: relative;
}
.social-anchor svg {
  fill: currentColor;
  height: 2.25rem;
  width: 2.25rem;
  z-index: 1;
  padding: 0.25rem;
}
.Social-anchor-circle {
  background-color: #333;
  border-radius: 50%;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
@media screen and (min-width: 500px) {
  .social-links {
    margin-bottom: 3rem;
  }
}
.Back-btn {
  align-items: center;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  margin-bottom: 2rem;
  position: relative;
  width: 2.5rem;
}
.Back-btn:focus {
  outline: 0;
}
.Back-icon {
  height: 2rem;
  width: 2rem;
}
.Back-btn-circle {
  background-color: #fff;
  border-radius: 50%;
  z-index: -1;
}
.Back-btn-circle,
.DetailContainer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.DetailContainer {
  -webkit-overflow-scrolling: touch;
  margin: 0 auto;
  max-width: 65rem;
  overflow-y: scroll;
  overscroll-behavior: none;
  z-index: 1;
}
.Detail {
  padding: 1.5rem 1.25rem;
}
.Detail-inner {
  max-width: 60rem;
}
.Detail-title {
  font-family: Rammetto One;
  font-size: 1.75rem;
  font-weight: 100;
  letter-spacing: -0.75px;
  margin-bottom: 2rem;
}
.Detail-anchor:focus,
.Detail-anchor:hover {
  text-decoration: underline;
}
.Icon-project {
  display: block;
  margin-bottom: 1rem;
  width: 4.5rem;
}
.Detail-iconlist {
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
}
.Detail-iconlist svg {
  font-size: 2.25rem;
}
.Icon-list-item {
  margin-right: 1rem;
  max-height: 3rem;
}
.Detail-panel--primary {
  max-width: 25rem;
  padding-right: 1rem;
}
.Detail-panel--secondary {
  margin-bottom: 4rem;
  max-width: 40rem;
}
.Detail-paragraph {
  margin-bottom: 3rem;
  padding-right: 1rem;
}
.Detail-paragraph ul li {
  margin: 08px 0;
}
.Detail-paragraph a {
  background-position: 0 16px;
  background-size: 100% 1.15vw;
  font-weight: 700;
}
.Detail-paragraph a:focus,
.Detail-paragraph a:hover {
  text-decoration: underline;
}
.Icon-open {
  margin-left: 0.5rem;
  max-height: 1.25rem;
  max-width: 1.25rem;
}
.Icon-fallback {
  font-size: 1.15rem;
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .Detail,
  .DetailContainer {
    overflow-y: inherit;
  }
  .Detail {
    display: flex;
    padding: var(--padding-lg);
    position: absolute;
  }
  .Detail-paragraph {
    margin-top: 4.25rem;
  }
  .Icon-project {
    margin-bottom: 1.25rem;
    width: 6rem;
  }
  .Detail-panel  .Icon-project {
    margin-bottom: 1.5rem;
    width: 8rem;
  }
  .Detail-title {
    font-size: 2rem;
    padding-right: 0.9rem;
  }
  .Detail-panel--primary {
    padding-right: 2rem;
    width: 55rem;
  }
  .Detail-panel--secondary {
    margin-bottom: 0;
    width: 100%;
  }
}
.ListItem_list__\+yzAr {
  margin-bottom: 2.5rem;
  overflow: hidden;
}
.ListItem_list__\+yzAr:last-child {
  margin-bottom: 0;
}
.ListItem_anchor__Ymc1b {
  display: inline-flex;
  max-width: 31rem;
}
.ListItem_anchor__Ymc1b:focus .ListItem_title__FcQDk,
.ListItem_anchor__Ymc1b:hover .ListItem_title__FcQDk {
  text-decoration: underline;
}
.ListItem_title__FcQDk {
  margin-bottom: 0.35rem;
}
.ListItem_icon__ScbkB {
  height: 2rem;
  position: relative;
  width: 2rem;
  z-index: 1;
}
.ListItem_iconWrap__oMZQu {
  height: 2rem;
  margin-right: 1rem;
  position: relative;
  width: 2rem;
}
.ListItem_para__riwCY {
  margin-bottom: 0;
}
:root {
  --padding: 6rem 10%;
  --padding-lg: 3rem;
  --itemWidth: 13.25rem;
  --itemHeight: 20rem;
}
@media screen and (min-width: 768px) {
  :root {
    --itemWidth: 17.8rem;
    --itemHeight: 26rem;
  }
}
::selection {
  background: #333;
  color: #fff;
}
::-moz-selection {
  background: #333;
  color: #fff;
}
button:focus {
  outline: none;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background-color: #333;
  box-sizing: border-box;
  font-size: 100%;
  min-height: 100vh;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "liga", "clig";
  font-feature-settings: "liga", "clig";
  background-color: #fff;
  color: #333;
  font-family: sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  -webkit-font-variant-ligatures: common-ligatures;
  font-variant-ligatures: common-ligatures;
  letter-spacing: -0.4px;
  line-height: 1.35;
  margin: 0;
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: #333;
    color: #fff;
  }
  .Social-anchor-circle {
    background-color: #fff;
  }
}
*,
:after,
:before {
  box-sizing: inherit;
}
.u-visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}
a {
  background-color: initial;
  text-decoration: none;
}
a,
a:focus,
a:hover {
  color: inherit;
}
a:active,
a:focus,
a:hover {
  outline: 0;
}
button {
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  background-color: initial;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: left;
  text-transform: none;
  width: auto;
}
a,
button,
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
h1,
h2 {
  font-size: 1.3rem;
  margin: 0;
}
h3 {
  font-size: inherit;
}
h3,
p {
  margin: 0 0 1rem;
}
h2 {
  margin: 0 0 0.5rem;
}
.root {
  min-height: 100vh;
  overflow: hidden;
}
.container {
  margin: 0 auto;
  max-width: 60rem;
  padding: 1.5rem 1.25rem;
}
.section {
  margin-bottom: 5rem;
}
.sub-heading-wrapper {
  margin-bottom: 1.35rem;
  overflow: hidden;
}
.sub-heading {
  font-family: Rammetto One;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .container {
    padding: 3rem;
    padding: var(--padding-lg);
    position: inherit;
  }
  body {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 468px) {
  .Detail-panel--secondary {
    max-width: 25rem;
  }
  .Detail-panel .Icon-project {
    margin-bottom: 1.25rem;
    width: 10rem;
  }
}
