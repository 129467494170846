.gallery {
  display: none;
  margin-left: 15px;
  max-width: 42rem;
  scroll-margin: 100px;
}
@supports (display: grid) {
  .gallery {
    display: block;
  }
}
.grid {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
  grid-auto-rows: 150px;
  grid-auto-flow: row dense;
}
.item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  background: #0c9a9a;
  color: #fff;
  grid-column-start: auto;
  grid-row-start: auto;
  color: #fff;
  background-size: cover;
  background-position: center;
  box-shadow: 0.5px 0.5px 0.5px 1px rgb(0 0 0 / 43%);
  transition: transform 0.3s ease-in-out;
  counter-increment: item-counter;
  border: 08px solid;
  border-radius: 3px;
  filter: grayscale(1);
}
.item:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
}
.item:hover {
  transform: scale(1.01);
  filter: grayscale(0.2);
}
.item:hover:after {
  opacity: 0;
}
.item--medium {
  grid-row-end: span 2;
}
.item--large {
  grid-row-end: span 3;
}
.item--xl-large {
  grid-row-end: span 4;
}
.item--full {
  grid-column-end: auto;
}
@media screen and (min-width: 768px) {
  .item--full {
    grid-column: 1/-1;
    grid-row-end: span 2;
  }
}
@media screen and (max-width: 480px) {
  .item--full {
    grid-column: 1;
    grid-row-end: span 2;
  }
  .gallery {
    margin-left: 0;
  }
}
.item__details {
  position: relative;
  z-index: 1;
  padding: 5px;
  color: #575757;
  background: #fff;
}
.item__details:before {
  content: counter(item-counter);
  font-weight: bold;
  font-size: 1.1rem;
  padding-right: 0.5em;
  color: #444;
}
